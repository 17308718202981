var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-task" },
    [
      _c("Nav", {
        attrs: {
          moduleTreeObj: _vm.moduleTreeObj,
          initParams: _vm.initParams,
          activeModuleKey: "viewTask",
        },
        on: {
          closeModal: _vm.handleClose,
          viewRoute: _vm.handleViewRoute,
          handleToTrackVideo: _vm.handleToTrackVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }